import {post} from '@/utils/axios'

export const getStudentList = (params) => post('/shida-user-center/SysDept/getTeacherManagerClassStudent', params)

export const resetStudentPwd = (params) => post('/shida-user-center/SysUser/resetPassword', params)

export const changeStudentStatus = (params) => post('/shida-user-center/SysUser/update', params)

export const getStudentCredits = (params) => post('/shida-user-biz/TbUserCredit/getTeacherManagerStudentCredits', params)

export const addRecord = (params) => post('/shida-user-biz/TbUserCreditRecord/saveOrUpdate', params)

export const seeRecord = params => post ('/shida-user-biz/TbUserCreditRecord/getCreditByUserId', params)

// 心理健康教育教学计划 - 老师管理的班级
export const psyHealthPlanCls1 = params => post('/shida-user-biz/TbMentalHealthPlanClassTeacher/getClassAssigned', params)

// 心理健康教育教学计划 - 班主任管理的班级
export const psyHealthPlanCls2 = params => post('/shida-user-center/SysDept/getHeadTeacherClass', params)

// 新增/更新心理健康教育教学计划
export const updatePsyHealthPlan = params => post('/shida-user-biz/TbMentalHealthPlan/saveOrUpdate', params)

// 获取心理健康教育教学计划
export const getPsyHealthPlan = params => post('/shida-user-biz/TbMentalHealthPlan/getData', params)

// 删除心理健康教育教学计划
export const delPsyHealthPlan = params => post('/shida-user-biz/TbMentalHealthPlan/remove', params)

// 心理健康教育教学计划课程明细
export const psyHealthPlanDetail = params => post('/shida-user-biz/TbMentalHealthPlanDetail/getList', params)

// 心理健康教育教学计划上传记录
export const psyHealthPlanRecord = params => post('/shida-user-biz/TbMentalHealthPlanDetail/saveOrUpdate', params)

// 班委账号列表
export const clsAdminUserList = params => post('/shida-user-center/SysUser/getUsersByClass', params)

// 新增班委账号
export const addClsAdminUser = params => post('/shida-user-center/SysUser/saveOrUpdateTwo', params)

// 班委启用/禁用
export const updateClsAdminUser = params => post('/shida-user-center/SysUser/update', params)

// 删除班委账号
export const delClsAdminUser = params => post('/shida-user-center/SysUser/deleteByUserId', params)

// 重置班委密码
export const resetPwdClsAdminUser = params => post('/shida-user-center/SysUser/resetPassword', params)
