<template>
  <div class="container">
    <div class="user-nav">
      <el-row>
        <el-form :model="queryParams" ref="queryForm" :inline="true">
          <el-form-item prop="deptId">
            <el-select
              v-model="queryParams.deptId"
              placeholder="请选择班级"
              style="width: 150px"
            >
              <el-option
                v-for="(item, index) in constData.classlist"
                :key="index"
                :label="item.deptName"
                :value="item.deptId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="isSpecial">
            <el-select
              v-model="queryParams.isSpecial"
              placeholder="特殊学生"
              clearable
              style="width: 150px"
            >
              <el-option
                v-for="(item, index) in constData.specialList"
                :key="index"
                :value="item.nm"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="studentStatus">
            <el-select
              v-model="queryParams.studentStatus"
              placeholder="请选择学生状态"
              clearable
              style="width: 150px"
            >
              <el-option
                v-for="(item, index) in constData.studentStatusList"
                :key="index"
                :value="item.nm"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="status">
            <el-select
              v-model="queryParams.status"
              placeholder="请选择账号状态"
              clearable
              style="width: 150px"
            >
              <el-option
                v-for="(item, index) in constData.statusList"
                :key="index"
                :label="item.nm"
                :value="item.val"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="userNameOrLoginName">
            <el-input
              v-model="queryParams.userNameOrLoginName"
              placeholder="学生姓名/学籍号"
              clearable
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleQuery"
              >搜索</el-button
            >
          </el-form-item>
        </el-form>
      </el-row>
    </div>
    <div class="main">
      <el-row type="flex" justify="space-between">
        <el-col :span="20">
          <h3>学生管理</h3>
        </el-col>
        <el-col :span="4" class="title-action">
          <el-button
            :disabled="mainList.list.length == 0"
            type="primary"
            plain
          >
            <download-excel
              :data="mainList.list"
              :fields="constData.exportListTh"
              name="学生列表.xls"
            >
              导出
            </download-excel>
          </el-button>
        </el-col>
      </el-row>
      <el-table
        :data="mainList.list"
        style="width: 100%; margin-top: 10px"
        border
        :header-cell-style="tableHeaderColor"
        :cell-style="cellStyleFun"
      >
        <el-table-column
          fixed="left"
          type="index"
          label="序号"
          width="50"
        ></el-table-column>
        <el-table-column
          fixed="left"
          prop="userName"
          width="150"
          label="学生姓名"
        ></el-table-column>
        <el-table-column prop="sex" label="性别" width="80"></el-table-column>
        <el-table-column
          prop="card"
          label="身份证号"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="loginName"
          label="学籍号"
          width="200"
        ></el-table-column>
				<el-table-column
				  prop="firstPassword"
				  label="初始密码"
				  width="100"
				></el-table-column>
        <el-table-column
          prop="deptName"
          label="班级"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="studentPosition"
          label="班级职务"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="isSpecial"
          label="特殊学生"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="studentStatus"
          label="学生状态"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="账号状态"
          width="100"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="220">
          <template slot-scope="scope">
            <el-button @click="openDetail(scope.row)" type="text"
              >查看详情</el-button
            >
            <el-button @click="openResetPwd(scope.row)" type="text"
              >重置密码</el-button
            >
            <el-button
              @click="openChangeStatus(scope.row)"
              type="text"
              v-if="scope.row.status == '启用'"
              >禁用</el-button
            >
            <el-button
              @click="openChangeStatus(scope.row)"
              type="text"
              v-if="scope.row.status == '禁用'"
              >启用</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </div>
    <el-dialog
      title="学生详情"
      :visible.sync="dialog.detail"
      width="600px"
      center
    >
      <p class="student-info">姓名：{{ stuDetail.userName }}</p>
      <p class="student-info">性别：{{ stuDetail.sex }}</p>
      <p class="student-info">身份证号：{{ stuDetail.card }}</p>
      <p class="student-info">学籍号(账号)：{{ stuDetail.loginName }}</p>
			<p class="student-info">初始密码：{{ stuDetail.firstPassword }}</p>
      <p class="student-info">绑定电话：{{ stuDetail.phoneNumber }}</p>
      <p class="student-info">所在学校：{{ stuDetail.parentName }}</p>
      <p class="student-info">所在班级：{{ stuDetail.deptName }}</p>
      <p class="student-info">班级职务：{{ stuDetail.studentPosition }}</p>
      <p class="student-info">学生状态：{{ stuDetail.studentStatus }}</p>
      <p class="student-info">账号状态：{{ stuDetail.status }}</p>
      <p class="student-info">特殊学生：{{ stuDetail.isSpecial }}</p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="确认重置密码"
      :visible.sync="dialog.resetPwd"
      width="600px"
      center
    >
      <p class="student-info">确定重置该用户的登录密码吗？</p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="confirmResetPwd">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="statusDialog.title"
      :visible.sync="dialog.changeStatus"
      width="600px"
      center
    >
      <p>
        <span v-if="statusDialog.status == '启用'">禁用</span
        ><span v-if="statusDialog.status == '禁用'">启用</span>学生{{
          statusDialog.stuNm
        }}?
      </p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="confirmChangeStatus">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getClass } from "@/api/public/search";
import {
  getStudentList,
  resetStudentPwd,
  changeStudentStatus,
} from "@/api/teacher/manager";
export default {
  data() {
    return {
      constData: {
        classlist: [],
        // 特殊学生选择项
        specialList: [
          {
            nm: "否",
          },
          {
            nm: "是",
          },
        ],
        // 学生状态选择项
        studentStatusList: [
          {
            nm: "正常",
          },
          {
            nm: "休学",
          },
          {
            nm: "复学",
          },
          {
            nm: "留级",
          },
          {
            nm: "跳级",
          },
          {
            nm: "市内转入",
          },
          {
            nm: "市外转出",
          },
          {
            nm: "市外转入",
          },
          {
            nm: "账号冻结",
          },
        ],
        // 账号状态选择项
        statusList: [
          {
            val: 0,
            nm: "启用",
          },
          {
            val: 1,
            nm: "禁用",
          },
        ],
        // 导出所需表头
        exportListTh: {
          学生姓名: "userName",
          性别: "sex",
          身份证号: "card",
          学籍号: "loginName",
          班级: "deptName",
          班级职务: "studentPosition",
          特殊学生: "isSpecial",
          学生状态: "studentStatus",
          账号状态: "status",
        },
      },
      total: 0,
      queryParams: {
        deptId: "",
        isSpecial: "",
        studentStatus: "",
        status: "",
        userNameOrLoginName: "",
        pageSize: 100,
      },
      mainList: {
        list: [],
        loading: false,
      },
      stuDetail: {},
      resetPwdForm: {
        stuNm: "",
        userId: "",
      },
      statusDialog: {
        userId: "",
        title: "",
        status: "",
        stuNm: "",
      },
      dialog: {
        detail: false,
        resetPwd: false,
        changeStatus: false,
      },
    };
  },
  watch: {
    "queryParams.deptId"(newV, oldV) {
      if (newV != "" && newV != oldV) {
        this.handleQuery();
      }
    },
  },
  created() {
    this.getClassList();
  },
  methods: {
    // 获取班级
    getClassList() {
      getClass({}).then((res) => {
        this.constData.classlist = res.data;
        this.queryParams.deptId = res.data[0].deptId;
      });
    },
    // 查询
    handleQuery() {
      this.getList();
    },
    getList() {
      getStudentList(this.queryParams).then((res) => {
        this.mainList.list = res.data.list;
        this.total = res.data.size;
      });
    },
    // 查看详情
    openDetail(row) {
      this.stuDetail = Object.assign({}, row);
      this.dialog.detail = true;
    },
    // 关闭详情
    closeDialog() {
      this.dialog.detail = false;
      this.dialog.resetPwd = false;
      this.dialog.changeStatus = false;
    },
    // 打开重置密码dialog
    openResetPwd(row) {
      this.resetPwdForm.stuNm = row.userName;
      this.resetPwdForm.userId = row.userId;
      this.dialog.resetPwd = true;
    },
    // 确定重置密码
    confirmResetPwd() {
      resetStudentPwd({
        userId: this.resetPwdForm.userId,
      }).then((res) => {
        this.closeDialog();
        this.$message.success("重置密码成功!");
        this.handleQuery();
      });
    },
    // 更改状态
    openChangeStatus(row) {
      let rs = row.status;
      this.statusDialog.status = rs;
      this.statusDialog.userId = row.userId;
      this.statusDialog.stuNm = row.userName;
      if (rs == "启用") {
        this.statusDialog.title = "禁用学生";
      } else if (rs == "禁用") {
        this.statusDialog.title = "启用学生";
      }
      this.dialog.changeStatus = true;
    },
    // 更改学生状态
    confirmChangeStatus(row) {
      let params = {
        userId: this.statusDialog.userId,
        status: 0,
      };
      if (this.statusDialog.status == "启用") {
        params.status = 1;
      }
      changeStudentStatus(params).then((res) => {
        let resultTxt = "禁用";
        if (params.status == 0) {
          resultTxt = "启用";
        }
        this.closeDialog();
        this.$message.success(resultTxt + "成功!");
        this.handleQuery();
      });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style scoped>
.user-nav {
  padding: 10px 12px;
  background: #fff;
  border: 1px solid rgba(153, 153, 153, 0.15);
  height: 63px;
  box-sizing: border-box;
}
.main {
  background-color: #fff;
  margin-top: 20px;
  padding: 10px 20px;
}
.main h3 {
  font-size: 16px;
  line-height: 32px;
  color: #616266;
  margin: 0;
}
.main .title-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
